<template>
  <img :src="image" :title="$props.title" :alt="$props.title" />
</template>
<script>
export default {
  name: 'Img',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  computed: {
    image () {
      return require(`@/assets/images/${this.$props.src}`)
    }
  }
}
</script>
<style>
img {
  width: 100%;
}
</style>
