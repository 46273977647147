<template>
  <section class="hero">
    <div class="hero__content">
      <h2 class="hero__title">Industrial vacuum equipment made to work for you</h2>
      <div class="hero__copy">
        <p>At GWS we manufacture, sell and hire a variety of industrial vacuum equipment for a wide range of industries and waste materials.</p>
        <router-link to="#" class="hero__link">Talk to our team today on 01724 856 665</router-link>
      </div>
    </div>
    <a href="#content"><Vector src="caret-down.svg" class="hero__down-arrow"></Vector></a>
  </section>
</template>
<script>
import Vector from '@/components/common/Vector.vue'
export default {
  name: 'Hero',
  components: { Vector }
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-5px) scale(1.1);
  }
}
.hero {
  @include sectionSpacing;
  background: map-get($brand-colors, bgb);
  background-image: url("../../assets/images/hero.jpg");
  background-size: cover;
  background-position: center;
  padding-top: vr(2);
  padding-bottom: vr(2);
  position: relative;
  @media(min-width: $md) {
    padding-top: vr(4);
    padding-bottom: vr(4);
  }
  &__down-arrow {
    width: 20px;
    height: 20px;
    position: absolute;
    left: calc(50vw - 10px);
    bottom: 20px;
    animation-name: bounce;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    ::v-deep svg, svg {
      .cls-1, path {
        fill: rgb(255, 255, 255);
      }
    }
    @media(min-width: $md) {
      display: none;
    }
  }
  &__link {
    @include blocklinks;
    @include helvetica('bold');
    text-transform: initial;
    padding: vr(1) vr(2) vr(1) vr(2);
    @include fontsize(1.2);
    @media(min-width: $md) {
      padding: vr(1) vr(2) vr(1) vr(2);
    }
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 8% 100%, 0px 50%)
  }
  &__content {
    @include grid;
    @include sectionSpacing;
  }
  &__title {
    @include grid-column(1, 8);
    @include hero;
    user-select: none;
    @media(min-width: $sm) {
      @include grid-row(1, 2);
      @include grid-column(1, 7);
      align-self: end;
    }
    @media(min-width: $md) {
      @include grid-column(1, 8);
    }
    @media(min-width: $lg) {
      @include grid-column(1, 6);
    }
    margin-bottom: vr(2);
  }
  &__copy {
    @include grid-column(1, 8);
    @include large;
    @media(min-width: $sm) {
      @include grid-row(2, 3);
      @include grid-column(1, 6);
      align-self: end;
      @include normal;
      color: map-get($brand-colors, textb);
    }
    @media(min-width: $md) {
      @include grid-column(1, 6);
      @include large;
    }
    p {
      margin-bottom: vr(1);
    }
    a {
      color: map-get($brand-colors, textb);
      @include helvetica('medium');
      @include helvetica('bold');
      &:after {
        color: map-get($brand-colors, textb);
      }
    }
  }
}
</style>
