import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import Home from './views/Home.vue'

Vue.use(Router)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  },
  {
    path: '/atex',
    name: 'atex',
    component: () => import(/* webpackChunkName: "atex" */ './views/Atex.vue')
  },
  {
    path: '/hire-sales',
    name: 'hiresales',
    component: () => import(/* webpackChunkName: "hiresales" */ './views/HireSales.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ './views/Blog.vue')
  },
  {
    path: '/equipment/vacuum',
    name: 'vacuum',
    component: () => import(/* webpackChunkName: "vacuum" */ './views/VacuumEquipment.vue')
  },
  {
    path: '/equipment/exhire-spares-repairs',
    name: 'exhire_spares_repairs',
    component: () => import(/* webpackChunkName: "exhire_spares_repairs" */ './views/equipment/Exhire_Spares_Repairs.vue')
  },
  {
    path: '/equipment/catalyst',
    name: 'catalyst',
    component: () => import(/* webpackChunkName: "catalyst" */ './views/CatalystEquipment.vue')
  },
  {
    path: '/equipment/vacuum/50-series-diesel',
    name: '50_series',
    component: () => import(/* webpackChunkName: "50_series" */ './views/equipment/50_series.vue')
  },
  {
    path: '/equipment/vacuum/30-series-electric',
    name: '30_series',
    component: () => import(/* webpackChunkName: "30_series" */ './views/equipment/30_series.vue')
  },
  {
    path: '/equipment/vacuum/104-series-diesel',
    name: '104_series',
    component: () => import(/* webpackChunkName: "104_series" */ './views/equipment/104_series.vue')
  },
  {
    path: '/equipment/vacuum/skips',
    name: 'skips',
    component: () => import(/* webpackChunkName: "hoppers" */ './views/equipment/Skips.vue')
  },
  {
    path: '/equipment/vacuum/hoppers',
    name: 'hoppers',
    component: () => import(/* webpackChunkName: "hoppers" */ './views/equipment/Hoppers.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ './views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'newsArticle',
    component: () => import(/* webpackChunkName: "newsArticle" */ './views/NewsArticle.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
  },
  {
    path: '/testimonials',
    name: 'testimonials',
    component: () => import(/* webpackChunkName: "testimonials" */ './views/Testimonials.vue')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
export { router, routes }
