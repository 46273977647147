<template>
  <article :class="`picturepanel ${classes}`">
    <router-link :to="$props.link || ''">
      <ExternalImg :src="$props.image" :srcSet="$props.imageSrcSet" v-if="isExternalImage" />
      <Img :src="$props.image" v-if="!isExternalImage" />
      <div class="picturepanel__content">
        <time class="picturepanel__date" v-if="$props.date">{{$props.date}}</time>
        <h1 class="picturepanel__title">{{$props.title}}</h1>
        <div class="picturepanel__text" v-if="$props.text" v-html="$props.text"></div>
        <span class="picturepanel__linkButton" v-if="$props.linkText">
          {{linkText}}
          <Vector src="caret-right.svg" class=""></Vector>
        </span>
      </div>
    </router-link>
  </article>
</template>

<script>
import Img from '@/components/common/Img'
import ExternalImg from '@/components/common/ExternalImg'
import Vector from '@/components/common/Vector'
export default {
  name: 'PicturePanel',
  props: {
    link: String,
    title: String,
    text: String,
    image: String,
    imageSrcSet: String,
    linkText: String,
    date: String,
    styleType: {
      type: String,
      default: () => {
        return 'Link'
      }
    }
  },
  components: { Img, Vector, ExternalImg },
  data () {
    return {
      classes: ''
    }
  },
  computed: {
    isExternalImage () {
      return this.image ? this.image.includes('http') : false
    }
  },
  created () {
    this.classes += this.$props.styleType === 'Link' ? 'picturepanel__link' : ''
    this.classes += this.$props.styleType === 'News' ? 'picturepanel__news' : ''
    this.classes += this.$props.styleType === 'NewsArticle' ? 'picturepanel__newsarticle' : ''
  }
}
</script>

<style scoped lang="scss">
.picturepanel {
  background: map-get($brand-colors, panel);
  &__image {
    width: 100%;
  }
  &__content {
    padding: vr(1);
    padding-bottom: vr(2);
    @media(min-width: $md) {
      padding: vr(2);
    }
  }
  &__title {
    @include sectionTitle;
    @include subTitle;
  }
  &__text {
    color: map-get($brand-colors, texta);
    margin-bottom: vr(2);
    ::v-deep {
      blockquote {
        font-weight: $font-weight-bold;
        font-style: italic;
      }
      p { margin-bottom: vr(2); }
      img, p img {
        width: auto;
        height: auto;
      }
    }
  }
  img {
    margin-bottom: vr(1.5);
  }
  &__news {
    .picturepanel__content {
      padding: 0;
    }
    img {
      margin-bottom: vr(1.5);
    }
    .picturepanel__date {
      @include helvetica('bold');
      margin-bottom: vr(1);
      display: inline-block;
    }
    .picturepanel__title {
      @include helvetica('bold');
      margin-bottom: vr(1);
      @include fontsize(1.25);
      &:after {
        display: none;
      }
      @media(min-width: $md) {
        @include fontsize(1.5);
      }
    }
    .picturepanel__text {
      p {
        margin-bottom: vr(2);
      }
    }
  }
  &.picturepanel__link {
    .picturepanel__text {
      width: 60%;
    }
  }
  &__linkButton {
    @include blocklinks;
    padding: vr(0.75);
    padding-left: vr(1.5);
    padding-right: vr(3);
    ::v-deep svg {
      fill: white;
      .cls-1 {
        fill: white;
      }
    }
    @media(min-width: $md) {
      padding: vr(0.75);
      padding-right: vr(3);
      padding-left: vr(1.5);
      @include fontsize(1);
    }
  }
}
.styleB {
  &.picturepanel {
    background: none;
    width: 90%;
  }
  &.picturepanel__link {
    .picturepanel__text {
      width: 90%;
    }
  }
  .picturepanel {
    &__linkButton {
    }
    &__title {
      @include sectionTitle;
      @include fontsize(1.2);
      color: map-get($brand-colors, heading);
      &:after {
        display: none;
      }
      @media(min-width: $md) {
        @include fontsize(1.4);
        padding-bottom: 0;
        margin-bottom: vr(1);
      }
    }
    &__content {
      padding: 0;
      padding-top: vr(0);
      padding-bottom: vr(2);
      @media(min-width: $md) {
        padding: 0;
        padding-bottom: vr(2);
      }
    }
  }
}
</style>
