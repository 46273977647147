<template>
  <svg :viewBox="viewbox" :xmlns="xmlns" v-html="dummy.innerHTML" :preserveAspectRatio="$props.preserveAspectRatio" />
</template>
<script>
require('innersvg-polyfill')
export default {
  name: 'CSVG',
  props: {
    src: {
      type: String,
      required: true
    },
    preserveAspectRatio: {
      type: String,
      default: () => 'xMaxYMax'
    }
  },
  data () {
    return {
      canvas: document.createElement('div')
    }
  },
  created () {
    this.canvas.innerHTML = this.asset
  },
  computed: {
    asset () {
      return require(`!!svg-inline-loader!@/assets/svg/${this.$props.src}`)
    },
    dummy () {
      return this.canvas.children[0]
    },
    viewbox () {
      return this.dummy.getAttribute('viewBox') || this.dummy.getAttribute('viewbox')
    },
    xmlns () {
      return this.dummy.getAttribute('xmlns')
    }
  }
}
</script>
<style>

</style>
