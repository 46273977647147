<template>
  <div class="home">
    <Hero />
    <a name="content"></a>
    <TextBlock title="Our equipment">
      <template slot="text">
        <p>
          As the UK’s leading manufacturer, with over 45 years’ experience, you can rely on us for advice on choosing the right equipment for your job.
        </p>
        <p>
          Our equipment is designed for performance, reliability and to be user-friendly. You can hire our equipment across the UK and Europe, and we sell new and ex-hire equipment worldwide.
        </p>
      </template>
      <template slot="links">
        <router-link to="/equipment/vacuum">
          Vacuum Equipment
          <Vector src="caret-right.svg" class=""></Vector>
        </router-link>
        <router-link to="/equipment/catalyst">
          Catalyst Equipment
          <Vector src="caret-right.svg" class=""></Vector>
        </router-link>
      </template>
    </TextBlock>
    <TextBlock title="Hire &amp; Sales" v-bind:alt='true'>
      <template slot="text">
        <p>
          Whether you’re looking to hire or buy, you first need to choose the best equipment for the job.
        </p>
      </template>
      <template slot="panels">
        <PicturePanel
          image="hiresales1.jpg"
          title="Hire"
          text="The UK’s largest fleet of industrial vacuum equipment."
          link="/hire-sales"
          />
        <PicturePanel
          image="hiresales2.jpg"
          title="Sales"
          text="Vacuum equipment designed for industry, and tailored to you."
          link="/hire-sales"
          />
      </template>
    </TextBlock>
    <section class="examples">
      <div class="examples__text">
        <TextBlock title="Waste Materials Collected:" v-bind:nested='true'>
          <template slot="text">
            <p>Dust, Ash, Aggregates, Liquids, Sludge, Catalyst, and many more - any material that will flow up a hose. </p>
          </template>
        </TextBlock>
        <TextBlock title="Machine Options:" v-bind:nested='true'>
          <template slot="text">
            <p>Electric and diesel models, waste collected into hoppers to fill 1 ton bags or skips as well as complete lorry mounted options.</p>
          </template>
        </TextBlock>
        <TextBlock title="Industry Examples:" v-bind:nested='true'>
          <template slot="text">
            <p>Power Stations, Steel Works, Foundries, Petrochemical, Chemical Manufacture, Carbon Manufacture, Incinerators, Shot Blast, Energy from Waste, Demolition, Silo Emptying, Tank Cleaning, Water Jetting, and more.</p>
          </template>
        </TextBlock>
      </div>
      <div class="examples__image">
        <Img src="home-waste-materials-collected.jpg" />
      </div>
    </section>
    <LatestNews />
  </div>
</template>

<script>
import TextBlock from '@/components/blocks/TextBlock'
import Hero from '@/components/home/Hero'
import Vector from '@/components/common/Vector'
import PicturePanel from '@/components/blocks/PicturePanel'
import Img from '@/components/common/Img'
import LatestNews from '@/components/blocks/LatestNews'

export default {
  name: 'Home',
  components: {
    Hero, TextBlock, Vector, PicturePanel, Img, LatestNews
  }
}
</script>
<style lang="scss" scoped>
.examples {
  @include grid;
  &__text {
    @include grid-column(1, 11);
    @include grid-row(2, 3);
    @media(min-width: $md) {
      @include grid-column(1, 6);
      @include grid-row(1, 2);
    }
  }
  &__image {
    @include grid-column(1, 11);
    @include grid-row(1, 2);
    margin-bottom: vr(1);
    @media(min-width: $md) {
      @include grid-column(7, 11);
    }
  }
}
</style>
