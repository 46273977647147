<template>
  <section :class="`textblock ${classes}`">
    <div class="textblock__wrapper">
      <h1 class="textblock__title" v-if="$props.title">{{$props.title}}</h1>
      <div class="textblock__col textblock__col--1" v-if="$slots['text']">
        <slot name="text"></slot>
      </div>
      <article class="textblock__col textblock__col--panels" v-if="$slots['panels']">
        <slot name="panels"></slot>
      </article>
      <article class="textblock__col textblock__col--2" v-if="$slots['links']">
        <slot name="links"></slot>
      </article>
    </div>
  </section>
</template>
<script>
export default {
  name: 'textblock',
  props: {
    title: String,
    alt: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    nested: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      classes: ''
    }
  },
  created () {
    this.classes += this.$props.alt === true ? 'textblock__alt' : ''
    this.classes += this.$props.nested === true ? 'textblock__nested' : ''
  }
}
</script>
<style lang="scss" scoped>
.textblock {
  margin-bottom: vr(1);
  @media(min-width: $md) {
    margin-bottom: vr(2);
  }
  &__wrapper {
    @include grid;
    padding: 0;
  }
  &__alt {
    background: map-get($brand-colors, bgc);
    @include sectionPadding;
    .textblock__col--2 a {
      padding-top: vr(1);
      padding-bottom: vr(1);
      &::before {
        border-bottom-color: map-get($brand-colors, bgc);
        border-left-color: map-get($brand-colors, bgc);
      }
    }
  }
  &__title {
    @include grid-column(1, 10);
    @include sectionTitle;
    @include grid-row(1, 2);
    color: map-get($brand-colors, heading);
  }
  &__col {
    @include grid-column(1, 9);
    @include grid-row(2, 3);
    margin-bottom: vr(1);
    p {
      margin-bottom: vr(1);
    }
    ul {
      margin-left: vr(1);
      column-count: 1;
      column-gap: vr(3);
      list-style: none;
      li {
        margin-bottom: vr(0.5);
        position: relative;
        &::before {
          content: "•";
          color: map-get($brand-colors, heading);
          position: absolute;
          transform: translateX(- vr(1));
        }
      }
      @media(min-width: $md) {
        column-count: 2;
        column-gap: vr(6);
        li {
          margin-bottom: vr(1);
        }
      }
    }
    @media(min-width: $md) {
      padding-right: vr(1);
    }
    &--2 {
      @include grid-row(4, 5);
      display: flex;
      flex-direction: column;
      @media(min-width: $md) {
        flex-direction: row;
      }
      a {
        @include blocklinks;
        max-width: 400px;
        margin-bottom: 15px;
        @media(min-width: $md) {
          margin-right: 30px;
        }
        ::v-deep svg {
          .cls-1, path {
           fill: white;
          }
        }
      }
    }
    &--panels {
      @include grid-column(1,10);
      @include grid-row(3, 4);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media(min-width: $md) {
        flex-direction: row;
      }
      .picturepanel {
        margin-bottom: 20px;
        @media(min-width: $md) {
          margin-bottom: 0;
          width: 48%;
        }
      }
    }
  }
}
.textblock:not(.textblock__nested) {
  @include sectionSpacing;
  .textblock__wrapper {
    @include grid;
    padding: vr(0) vr(1);
  }
}
</style>
