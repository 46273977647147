<template>
  <div id="app" :class="envMode" :data-build="envMode">
    <PageHeader :classStyle='classStyles' />
    <transition name="fade">
      <router-view/>
    </transition>
    <PageFooter />
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
export default {
  name: 'App',
  metaInfo: {
    title: 'GWS Vacuum Equipment',
    titleTemplate: '%s | GWS'
  },
  components: {
    PageHeader, PageFooter
  },
  data () {
    return {
      classStyles: '',
      envMode: process.env.NODE_ENV
    }
  },
  methods: {
    getNavClass () {
      if (this.$route.path === '/') {
        this.classStyles = 'header__home'
      } else {
        this.classStyles = 'header__white'
      }
    }
  },
  beforeCreate () {
    this.$store.commit('INIT_STORE')
  },
  beforeUpdate () {
    this.getNavClass()
  },
  mounted () {
    this.getNavClass()
  },
  created () {
    // console.log(process.env.VUE_APP_API_BASE_URL)
    this.$store.dispatch('getPostData')
    this.$store.dispatch('getTestimonialData')
  }
}
</script>
<style lang="scss">
body, h1, h2, h3, h4, h5, a, ul, ol, li, p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
body {
  @include normal;
  @include helvetica('regular');
  color: map-get($brand-colors, texta);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  @include link;
}
#app {
  overflow-x: hidden;
  &.development::after {
    content: 'XSmall';
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: rgb(200,200,200);
    opacity: 0.4;
    padding: vr(0.5);
    z-index: 100;
    display: none;
    @media (min-width: $sm) {
      content: 'Small';
    }
    @media (min-width: $tb) {
      content: 'Tablet';
    }
    @media (min-width: $md) {
      content: 'Medium';
    }
    @media (min-width: $lg) {
        content: 'Large';
    }
    @media (min-width: $xl) {
      content: 'X-Large';
    }
    @media (min-width: $xxl) {
      content: 'Huge';
    }
  }
}
.link--arrow {
  position: relative;
  padding-right: vr(1);
  &::after {
    content: '>';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: map-get($brand-colors, heading);
    font-size: vr(1.5);
    margin-left: vr(0.5);
  }
}
.fade-enter-active, .fade-leave-active {
  transition-property: all;
  transition-duration: .2s;
}

.fade-enter-active {
  transition-delay: .2s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
  transform: translateX(-50px);
}

</style>
