<template>
  <header :class="`header ${classStyle}`">
    <ContactStrip></ContactStrip>
    <div class="header__wrapper">
      <router-link to="/" @click.prevent="closeMenu('/')" class="header__logo">
        <Vector src="GWS-Logo.svg" class="header__logo-image"></Vector>
      </router-link>
      <input :checked="isChecked" type="checkbox" id="hamburger__target" class="header__nav-control" />
      <Hamburger @click.native="handleMenuClick"></Hamburger>
      <nav class="header__nav menu__target">
        <label class="header__nav-link header__nav-link-label" @click.prevent="labelClick" for="sector__target">
         Equipment
          <div class="header__nav__subnav">
            <router-link to="/equipment/vacuum" @click.prevent="closeMenu('/equipment/vacuum')" class="header__nav-link">Vacuum Equipment</router-link>
            <router-link to="/equipment/catalyst" @click.prevent="closeMenu('/equipment/catalyst')" class="header__nav-link">Catalyst Handling</router-link>
          </div>
        </label>
        <router-link to="/atex" @click.prevent="closeMenu('/atex')" class="header__nav-link">Atex</router-link>
        <router-link to="/hire-sales" @click.prevent="closeMenu('/hire-sales')" class="header__nav-link">Hire &amp; Sales</router-link>
        <router-link to="/about" @click.prevent="closeMenu('/about')" class="header__nav-link">About</router-link>
        <label class="header__nav-link header__nav-link-label" @click.prevent="labelClick" for="sector__target">
          News
          <div class="header__nav__subnav">
            <router-link to="/news" class="header__nav-link" @click.prevent="closeMenu('/news')">News Articles</router-link>
            <router-link to="/testimonials" class="header__nav-link" @click.prevent="closeMenu('/testimonials')">Testimonials</router-link>
          </div>
        </label>
        <router-link to="/contact" class="header__nav-link" @click.prevent="closeMenu('/contact')">Contact</router-link>
      </nav>
    </div>
  </header>
</template>
<script>
import Hamburger from '@/components/common/Hamburger.vue'
import Vector from '@/components/common/Vector.vue'
import ContactStrip from '@/components/blocks/ContactStrip'
export default {
  name: 'PageHeader',
  props: {
    classStyle: String
  },
  components: {
    Hamburger, Vector, ContactStrip
  },
  data () {
    return {
      burgerChecked: false
    }
  },
  update () {
  },
  methods: {
    handleMenuClick () {
      this.burgerChecked = !this.burgerChecked
    },
    closeMenu (e) {
      this.$router.push({ path: e })
      this.handleMenuClick()
      return false
    },
    labelClick (e) {
      // this one is fun - if we enable this, iOS9 will allow label clicks
      // it's a dead click without. yay.
    }
  },
  computed: {
    isChecked () {
      return this.burgerChecked ? 'checked' : ''
    }
  }
}
</script>
<style lang="scss">
  label[for=sector__target] {
    position: relative;
    .header__nav__subnav {
      transform: none;
    }
    &:hover {
      padding-bottom: 0;
      color: map-get($brand-colors, textb);
      background-color: map-get($brand-colors, navhover);
      .header__nav__subnav {
        opacity: 1;
        display: block;
        left: 0;
        top: 100%;
        @media(min-width: $md) {
          border-radius: 5px;
          border-top-left-radius: 0px;
          box-shadow: 5px 5px 15px -2px rgba(0, 0, 0, .4);
          position: absolute;
        }
      }
    }
  }
  .header__nav-link:hover,
  label[for=sector__target]:hover,
  label[fot=sector__target]:focus
  {
    pointer-events: all;
    color: map-get($brand-colors, textb);
    background-color: map-get($brand-colors, navhover);
    .header__nav__subnav {
      opacity: 1;
      display: block;
      left: 0;
      top: 100%;
      @media(min-width: $md) {
        width: 220px;
        position: absolute;
      }
    }
  }
.header {
  transition: all 200ms ease-in-out;
  background-color: map-get($brand-colors, bga);
  color: map-get($brand-colors, textb);
  @include helvetica('medium');
  &__wrapper {
    @include gridWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: vr(4);
    @media(min-width: $md) {
      justify-content: flex-start;
    }
  }
  &__logo {
    display: flex;
    max-width: 75%;
    @media(min-width: $md) {
      max-width: 30%;
      margin-top: -40px;
    }
    &-image {
      fill: white;
      path:last-child {
        stroke: white;
      }
      height: vr(3);
      @media(min-width: $md) {
        height: vr(3.5);
      }
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: map-get($brand-colors, bga);
    transition: transform 0.2s ease-out;
    transform: translateY(-100%);
    z-index: 2;
    @media(min-width: $md) {
      box-shadow: none;
      padding: vr(2) vr(1);
      padding-right: 0;
      top: 0;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      margin-left: auto;
      background-color: transparent;
      width: cols(6.5, $md);
      transform: none;
      transition: none;
    }
    @media (min-width: $lg) {
      padding: vr(2) vr(1);
      width: cols(5, $lg);
      padding-right: 0;
    }
    @media (min-width: $xl) {
      width: cols(5, $xl);
      padding-right: 0;
    }
    &-link {
      @include large;
      padding: vr(0.5) vr(0.5);
      text-transform: uppercase;
      color: map-get($brand-colors, textb);
      transition: opacity 0.2s 0.2s ease-out, transform 0.2s 0.2s ease-out;
      display: block;
      opacity: 0;
      transform: translateX(-100%);
      position: relative;
      @media(min-width: $md) {
        @include menuLink;
        transform: none;
        opacity: 1;
      }
      &:first-child {
        &:before {
          content: ' ';
          display: block;
          width: 120%;
          position: absolute;
          top: 0px;
          border-bottom: solid 1px #6ba7c2;
          left: 0px;
          transform: translateX(-20px);
          @media(min-width: $md) {
            transform: none;
          width: 50%;
          }
        }
        @media(min-width: $md) {
          &:before {
            display: none;
          }
        }
      }
      &:after {
        content: ' ';
        display: block;
        width: 120%;
        position: absolute;
        bottom: 0px;
        border-bottom: solid 1px #6ba7c2;
        left: 0px;
        transform: translateX(-20px);
        @media(min-width: $md) {
          transform: translateX(20px);
          width: 50%;
        }
      }
      @media(min-width: $md) {
        &:after {
          display: none;
        }
      }
      &-label {
        z-index: 3;
        pointer-events: all;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &-control {
      display: none;
    }
    &__subnav {
      top: 0;
      left: 0;
      width: 120vw;
      transform: translateX(-20px);
      height: 100%;
      transform: translateX(100vw);
      background-color: map-get($brand-colors, navhover);
      animation-play-state: running;
      z-index: 3;
      display: none;
      padding: vr(1) 0 0 0;
      @media(min-width: $md) {
        padding: vr(3) vr(1) vr(2);
        position: absolute;
        display: none;
        width: auto;
        height: auto;
        padding: vr(0.5);
      }
      .header__nav-link {
        color: map-get($brand-colors, textb);
        pointer-events: all;
        @include fontsize(1.2);
        text-transform: capitalize;
        @media(min-width: $lg) {
          @include fontsize(1);
          white-space: nowrap;
          padding: vr(0.5) vr(4) vr(0.5) vr(1);
          margin-right: vr(1);
          &:last-child {
            padding-bottom: 0;
          }
        }
        position: relative;
        &:after {
          display: block;
          position: absolute;
          bottom: 0px;
          content: '';
          width: 100%;
          height: 1px;
          background: map-get($brand-colors, bga);
          @media(min-width: $md) {
            width: 75%;
          }
        }
        &:last-child {
          @media(min-width: $md) {
            margin-bottom: vr(0.5);
          }
          &:after {
            display: none;
          }
        }
      }
      label {
        padding-top: vr(1);
        pointer-events: all;
        @media(min-width: $lg) {
          display: none;
        }
      }
    }
  }
  &__white {
    @media(max-width: $tb-max) {
      .header__logo-image {
        fill: white;
        path {
          fill: white;
        }
      }
    }
    @media(min-width: $md) {
      background: rgb(255, 255, 255);
      * {
        color: map-get($brand-colors, texta);
        fill: map-get($brand-colors, heading);
        //stroke: map-get($brand-colors, heading);
      }
      .header__logo-image path:last-child {
        stroke: map-get($brand-colors, heading);
      }
    }
  }
  &__home {
    .header__logo-image {
      path {
        fill: white;
      }
    }
  }
}
.line-break {
  display: none;
  @media(min-width: $lg) {
    display: block;
  }
}
</style>
