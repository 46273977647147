<template>
  <section class="latestnews">
    <div class="latestnews__wrapper">
      <h1 class="latestnews__title">News</h1>
      <div class="latestnews__articles">
        <News v-for="news in articles" v-bind:key="news.id"
          styleType="News"
          v-bind:title="news.title.rendered"
          v-bind:image="news.featured_thumbnail_image_url"
          v-bind:date="formatDate(news.date)"
          v-bind:link="`/news/${news.slug}`" />
      </div>
    </div>
  </section>
</template>

<script>
import News from '@/components/blocks/PicturePanel'
import moment from 'moment'

export default {
  name: 'LatestNews',
  components: {
    News
  },
  props: {
  },
  data () {
    return {
      articles: this.$store.state.news.slice(0, 2)
    }
  },
  methods: {
    formatDate (wpDate) {
      return moment(wpDate).format('Do MMMM YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.latestnews {
  @include sectionPadding;
  background: map-get($brand-colors, panel);
  &__wrapper {
    @include grid;
  }
  &__title {
    @include grid-column(1,11);
    @include sectionTitle;
    color: map-get($brand-colors, heading);
    margin-bottom: vr(2);
    @media(min-width: $md) {
      margin-bottom: vr(3.5);
    }
  }
  &__articles {
    @include grid-column(1,11);
    max-width: 80vw;
    display: flex;
    .picturepanel {
      margin-right: 5%;
      margin-bottom: vr(2);
    }
    flex-direction: column;
    @media(min-width: $md) {
      flex-direction: row;
      .picturepanel {
      }
    }
  }
}
</style>
