<template>
  <div class="gridWrap">
    <section class="contactStrip">
      <div class="contactStrip__item">
        T. <a class="contactStrip__link" href="tel:+441724856665">01724 856 665</a>
      </div>
      <div class="contactStrip__item">
        E. <a class="contactStrip__link" href="mailto:mail@gwsvacuum.co.uk">mail@gwsvacuum.co.uk</a>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'ContactStrip'
}
</script>
<style lang="scss" scoped>
.gridWrap {
  @media(min-width: $md) {
    @include grid(12);
  }
}
.contactStrip {
  @include grid-column(1, 13);
  padding: vr(0.2) vr(1);
  @include fontsize(0.8);
  justify-content: space-around;
  @media(max-width: $xs + 10) {
    @include fontsize(0.7);
  }
  @media(min-width: $md) {
    justify-content: center;
    padding: vr(0.5) vr(1);
    @include grid-column(7, 13);
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    @include fontsize(0.95);
    box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.15);
  }
  @media(min-width: $lg) {
    @include grid-column(9, 13);
  }
  @media(min-width: $xl) {
    @include grid-column(9, 13);
  }
  @include helvetica('bold');
  background: #ffbc12;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
  color: map-get($brand-colors, texta);
  &__link {
    color: map-get($brand-colors, textb);
  }
  &__item:first-child {
    margin-right: 10px;
    @media(min-width: $md) {
      margin-right: 20px;
    }
  }
}
</style>
