import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

const state = {
  testimonials: [],
  news: []
}

const mutations = {
  INIT_STORE (state) {
    if (localStorage.getItem('store')) {
      this.replaceState(
        Object.assign(state, JSON.parse(localStorage.getItem('store')))
      )
    }
  },
  ADD_TESTIMONIALS (state, data) {
    state.testimonials = data
    // console.log('adding testimonials here')
  },
  ADD_NEWS (state, data) {
    state.news = data
    // console.log('adding news here')
  }
}

const actions = {
  getPostData ({ commit }) {
    // console.log('get post data vuex')
    axios.get('news?_embed').then(response => {
      // console.log(response.data)
      commit('ADD_NEWS', response.data)
    }).catch(() => {
      // console.log('Could not get data', err)
    })
  },
  getTestimonialData ({ commit }) {
    // console.log('get testimonial data vuex')
    axios.get('testimonials?_embed').then(response => {
      // console.log(response.data)
      commit('ADD_TESTIMONIALS', response.data)
    }).catch(() => {
      // console.log('Could not get data', err)
    })
  }
}

const getters = {
  getTestimonials: state => {
    const halfway = Math.round(state.testimonials.length / 2)
    return [
      state.testimonials.slice(0, halfway),
      state.testimonials.slice(halfway, state.testimonials.length)
    ]
  }
}

const store = new Vuex.Store({ state, mutations, actions, getters })

store.subscribe((mutation, state) => {
  localStorage.setItem('store', JSON.stringify(state))
})

export default store
