import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.use(VueGtm, {
  id: 'GTM-PLVXX2R',
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether to display console logs debugs (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
