<template>
  <footer class="footer">
    <div class="footer__bottom">
      <div class="footer__wrapper">
        <div class="footer__bottom-nav">
          <router-link to="/about">About Us</router-link>
          <router-link to="/equipment/vacuum">Vacuum Equipment</router-link>
          <router-link to="/hire-sales">Hire &amp; Sales</router-link>
          <router-link to="/equipment/catalyst">Catalyst Handling</router-link>
          <router-link to="/atex">ATEX</router-link>
          <router-link to="/news">News</router-link>
          <router-link to="/contact">Contact</router-link>
        </div>
        <div class="footer__bottom-contact_social">
          <p class="footer__bottom-contact">For more information about us, our products and services give us a call on <a class="nowrap" href="tel:+441724856665">44 (0)1724 856 665</a> or email <a class="nowrap" href="mailto:mail@gwsvacuum.co.uk">mail@gwsvacuum.co.uk</a></p>
          <div class="footer__bottom-social">
            <a target="_blank" href="https://www.facebook.com/pages/GWS-Vacuum-Equipment/1602211903378794?fref=ts">
              <Vector src="facebook.svg" class="footer__bottom-svg" />
            </a>
            <a target="_blank" href="https://twitter.com/GwsVacuum">
              <Vector src="twitter.svg" class="footer__bottom-svg" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/gws-vacuum-equipment">
              <Vector src="linkedin.svg" class="footer__bottom-svg footer__bottom-svg" />
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCFxGPjZFthO_v0oSPer0a6g/featured">
              <Vector src="youtube.svg" class="footer__bottom-svg footer__bottom-svg-last footer__bottom-svg-youtube" />
            </a>
          </div>
          <p class="footer__bottom-copyright">Copyright ©{{copyDate}} GWS Engineers Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Vector from '@/components/common/Vector'
export default {
  name: 'PageFooter',
  components: {
    Vector
  },
  data: function () {
    return {
      copyDate: new Date().getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  overflow: hidden;
  color: map-get($brand-colors, textb);
  background-color: map-get($brand-colors, bga);
  position: relative;
  &__bottom {
    padding-top: vr(6);
    padding-bottom: vr(6);
    width: 100%;
  }
  &__wrapper {
    @include grid;
  }
  &::before{
    content: '';
    display: block;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 400px;
    height: 400px;
    background: #007bb3;
    transform: rotateZ(40deg) translateX(300px);
  }
}
.footer__bottom {
  &-contact_social {
    @include grid-row(2, 3);
    @include grid-column(1, 11);
    @include grid(7);
    padding: 0;
    @media(min-width: $md) {
      @include grid-row(1, 2);
      @include grid-column(6, 11);
      @include grid(7);
    }
    @media(min-width: $lg) {
      @include grid-row(1, 2);
      @include grid-column(5, 11);
      @include grid(7);
    }
    .nowrap {
      white-space: nowrap;
    }
  }
  &-svg {
    width: 30px;
    height: 30px;
    margin-right: 50px;
    fill: map-get($brand-colors, textb);
    fill: rgb(255, 255, 255);
    path {
      fill: rgb(255, 255, 255);
      stroke: rgb(255, 255, 255);
    }
    &-last {
      margin-right: 0;
    }
    &-youtube {
      ::v-deep .cls-1 {
        fill: rgb(255, 255, 255) !important;
      }
    }
  }
  &-contact {
    @include h3;
    -moz-osx-font-smoothing: auto;
    margin-bottom: vr(3);
    @include grid-column(1,6);
    @media(min-width: $md) {
      @include grid-column(1,7);
      @include grid-row(1,2);
    }
    @media(min-width: $lg) {
      @include grid-column(1,5);
      @include grid-row(1,2);
    }
    @include helvetica('medium');
    @include fontsize(1.3);
    a {
      display: block;
      color: map-get($brand-colors, textb);
      text-decoration: underline;
      &:first-child {
        margin-bottom: vr(1);
      }
      @media(min-width: $md) {
        display: inline;
        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &-copyright {
    z-index: 2;
    @include grid-column(1,6);
    @media(min-width: $md) {
      @include grid-column(1,6);
    }
    @media(min-width: $lg) {
      @include grid-column(1,5);
    }
    @include grid-row(3,4);
    @include fontsize(1);
  }
  &-social {
    @include grid-column(1,6);
    @include grid-row(2,3);
    padding-bottom: vr(1.5);
    a {
      color: map-get($brand-colors, textb);
    }
    @media(min-width: $md) {
      @include grid-column(1,5);
    }
  }
  &-nav {
    display: none;
    @include grid-column(1, 5);
    @include helvetica('bold');
    @include fontsize(2);
    margin-bottom: vr(2);
    a {
      display: block;
      color: map-get($brand-colors, textb);
    }
    @media(min-width: $md) {
      display: block;
    }
  }
}
</style>
