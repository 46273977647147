<template>
  <img :src="$props.src" :srcset="$props.srcSet" :title="$props.title" :alt="$props.title" />
</template>
<script>
export default {
  name: 'ExternalImg',
  props: {
    src: {
      type: String,
      required: true
    },
    srcSet: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    }
  }
}
</script>
<style>
img {
  width: 100%;
}
</style>
